<template>
  <div class="main-div">
    <div class="slid-div">
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <p class="quote-style">
          {{ randomSlides.quote }} <br>
          -<span class="author-style"> {{ randomSlides.author }}</span>
        </p>
      </v-row>
    </div>
    <div class="container">
      <div class="item item-1" />
      <div class="item item-2" />
      <div class="item item-3" />
      <div class="item item-4" />
    </div>
  </div>
</template>
<script>
import ErrorStore from 'src/errors';
import { dsAppDomainUrl } from 'src/app-globals';
import Constants from 'src/constants';

export default {
  name: 'WelcomePage',

  data () {
    return {
      slides: [
        {
          quote: '"The greatest glory in living lies not in never falling, but in rising every time we fall."',
          author: 'Nelson Mandela',
        },
        {
          quote: '"Always remember that you are absolutely unique. Just like everyone else."',
          author: 'Margaret Mead',
        },
        {
          quote: '"You will face many defeats in life, but never let yourself be defeated."',
          author: 'Maya Angelou',
        },
        {
          quote: '"The future belongs to those who believe in the beauty of their dreams."',
          author: 'Eleanor Roosevelt',
        },
        {
          quote: '"Imagination is more important than knowledge."',
          author: 'Albert Einstein',
        },
      ],
      showArrows: false,
      errors: new ErrorStore(),
      dsDomainUrl: dsAppDomainUrl,
    };
  },
  computed: {
    randomSlides () {
      const slideItem = this.slides[Math.floor(Math.random() * this.slides.length)];
      return slideItem;
    },
  },
  mounted () {
    window.addEventListener('message', this.iframeEvent, false);
    setTimeout(() => {
      this.toDashboard();
    }, 2000);
  },
  methods: {
    async iframeEvent (event) {
      if (event.origin !== this.dsDomainUrl) {
        return;
      }
      localStorage.setItem('scp-user', event.data.scpUser);
      localStorage.setItem('ds-user', event.data.dsUser);
      this.sendResponseToParent();
    },
    sendResponseToParent () {
      const scpUser = localStorage.getItem('scp-user');
      if (scpUser === null) {
        window.location.href = this.dsDomainUrl + '/logout-all';
        return;
      }
      window.parent.postMessage('HELLO_PARENT', this.dsDomainUrl);
    },
    toDashboard () {
      const scpUser = localStorage.getItem('scp-user');
      if (scpUser === null) {
        setTimeout(() => {
           this.$store.dispatch('alert/onAlert', {
            message: 'The connection has timed out, please login again.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          setTimeout(() => {
            window.location.href = this.dsDomainUrl + '/logout-all';
          }, 2000);
        }, 6000);
        return;
      }
      this.$router.push({ name: 'Dashboard' });
    },
  },
};
</script>
<style scoped>
.container {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.item {
  width: 25px;
  height: 25px;
  position: absolute;
}

.item-1 {
  background-color: #C62828;
  top: 0;
  left: 0;
  z-index: 1;
  animation: item-1_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-2 {
  background-color: #7A45E5;
  top: 0;
  right: 0;
  animation: item-2_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-3 {
  background-color: #1B91F7;
  bottom: 0;
  right: 0;
  z-index: 1;
  animation: item-3_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-4 {
  background-color: #FAC24C;
  bottom: 0;
  left: 0;
  animation: item-4_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}
.main-div {
  background: #263238;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
@media only screen and (max-width:480px) {
  .main-div {
    position: relative;
  }
}

@keyframes item-1_move {
  0%, 100% {transform: translate(0, 0)}
  25% {transform: translate(0, 35px)}
  50% {transform: translate(35px, 35px)}
  75% {transform: translate(35px, 0)}
}

@keyframes item-2_move {
  0%, 100% {transform: translate(0, 0)}
  25% {transform: translate(-35px, 0)}
  50% {transform: translate(-35px, 35px)}
  75% {transform: translate(0, 35px)}
}

@keyframes item-3_move {
  0%, 100% {transform: translate(0, 0)}
  25% {transform: translate(0, -35px)}
  50% {transform: translate(-35px, -35px)}
  75% {transform: translate(-35px, 0)}
}

@keyframes item-4_move {
  0%, 100% {transform: translate(0, 0)}
  25% {transform: translate(35px, 0)}
  50% {transform: translate(35px, -35px)}
  75% {transform: translate(0, -35px)}
}
.quote-style {
  color: #ECEFF1;
  font-family: 'Times New Roman', Times, serif;
  font-style: italic;
  font-size: 18px;
  letter-spacing: 2px;
  max-width:50ch;
  word-wrap:break-word;
  text-align: center;
  margin: auto;
}
@media only screen and (max-width:480px) {
  .quote-style {
    max-width:40ch;
  }
}
.author-style {
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-style: italic;
  font-size: 18px;
  letter-spacing: 2px;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.slid-div {
  position: absolute;
  top: -200px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
